import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import telecom from "../assets/telecom.jpg";
import ecommerce from "../assets/ecommerce.jpg";
import financialServices from "../assets/financial_services.jpg";
import insurance from "../assets/insurance.jpg";
import healthtech from "../assets/healthtech.jpg";
import recruitingStaffing from "../assets/recruiting_staffing.jpg";
import travelHospitality from "../assets/travel_hospitality.jpg";
import edTech from "../assets/edTech.jpg";

const SolutionsPage = ({ toggleModal }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sections = [
    {
      id: 1,
      heading: "Telecom",
      subheading: "Enhance Customer Experience with AI-Powered Solutions",
      description: `Enhance customer experience in the telecom industry with our AI-Powered Unified Customer Interaction Platform. Semantic Intelligence on Interactions ensures precise and effective call handling by providing insights from customer conversations, improving problem-solving and satisfaction. The Agent Empowerment Suite delivers continuous, tailored training, transforming agents into highly skilled professionals capable of managing complex queries. Comprehensive Reporting and Analytics offer detailed performance metrics and trend analysis to optimize workflows. Additionally, the platform’s Easy Integration and Data Privacy features ensure smooth operation within existing systems while maintaining robust security.`,
      useCase: {
        title: "Use Case: Call Center Optimization",
        points: [
          "Semantic Intelligence on Interactions: Analyzes customer calls to identify common issues and trends, providing agents with insights for faster resolution.",
          "Agent Empowerment Suite: Offers tailored training modules to enhance problem-solving skills, ensuring high-quality support.",
          "Comprehensive Reporting and Analytics: Tracks key performance metrics like call resolution times and customer satisfaction scores to optimize operations.",
          "Easy Integration and Data Privacy: Integrates seamlessly with existing CRM systems while ensuring customer data protection with top-notch security measures.",
        ],
      },
      industryInsights: {
        title: "Industry Insights",
        points: [
          "AI integration in telecom is transforming customer service through chatbots and large language models (LLMs) like GPT-4, reducing call center deflection and improving customer satisfaction. By 2024, personalized AI-driven interactions will be a key differentiator, enhancing service delivery and operational efficiency.",
          "AI-driven smart scheduling can improve staffing accuracy, resulting in cost savings and increased sales. Predictive models optimize workforce management, leading to better customer satisfaction and efficiency improvements.",
          "AI chatbots handling complex tasks improve customer engagement and retention, as per GlobalData and AT&T’s report.",
        ],
      },
      buttonLabel: "Get Demo",
      image: telecom,
    },
    {
      id: 2,
      heading: "Ecommerce",
      subheading: "Revolutionize Your Ecommerce Customer Service",
      description:
        "Revolutionize your ecommerce customer service with our platform's advanced features. Semantic Intelligence on Interactions analyzes customer conversations to extract valuable insights, enhancing agent support. The Agent Empowerment Suite provides continuous learning and tailored training, ensuring agents address queries efficiently. Comprehensive Reporting and Analytics offer detailed performance metrics and actionable insights to optimize customer service. Plus, Easy Integration and Data Privacy features guarantee seamless integration with your existing infrastructure while safeguarding sensitive customer information.",
      useCase: {
        title: "Use Case: Personalized Shopping Assistance",
        points: [
          "Semantic Intelligence on Interactions: Analyzes customer inquiries to provide personalized product recommendations and enhance the shopping experience.",
          "Agent Empowerment Suite: Continuous training equips agents to handle a wide range of queries, from product information to order tracking.",
          "Comprehensive Reporting and Analytics: Tracks metrics such as average handling time and conversion rates to improve customer service strategies.",
          "Easy Integration and Data Privacy: Integrates effortlessly with ecommerce platforms while ensuring customer data privacy and security.",
        ],
      },
      industryInsights: {
        title: "Industry Insights",
        points: [
          "Personalized Shopping Experiences: AI in ecommerce drives personalized marketing and customer service, increasing conversion rates by delivering tailored recommendations and offers. Semantic intelligence helps understand customer preferences, boosting sales.",
          "Market Growth: AI is transforming ecommerce by enhancing customer interactions and operational efficiency. The AI in retail market is projected to reach USD 23.32 billion by 2026, driven by applications like personalized recommendations, dynamic pricing, and customer service chatbots【Grand View Research】.",
        ],
      },
      buttonLabel: "Get Demo",
      image: ecommerce,
    },
    {
      id: 3,
      heading: "Financial Services",
      subheading: "Boost Customer Confidence with Intelligent Interactions",
      description:
        "For financial services, where trust and efficiency are paramount, our AI-powered platform excels in enhancing customer interactions through advanced Semantic Intelligence on Interactions, offering insights and support for agents. The Agent Empowerment Suite provides ongoing training and upskilling, enabling agents to handle customer needs with precision and confidence. Comprehensive Reporting and Analytics deliver critical performance metrics and trend analysis, facilitating data-driven decision-making. Additionally, the platform’s Easy Integration and Data Privacy features ensure a secure, seamless implementation within your existing systems.",
      useCase: {
        title: "Use Case: Fraud Detection and Prevention",
        points: [
          " Semantic Intelligence on Interactions: Monitors customer interactions for signs of fraudulent activity and alerts agents.",
          "Agent Empowerment Suite: Provides agents with tools and knowledge to address security concerns and educate customers on fraud prevention.",
          "Comprehensive Reporting and Analytics: Analyzes interaction data to identify patterns and trends in fraudulent behavior.",
          "Easy Integration and Data Privacy: Ensures seamless integration with financial systems and adheres to strict data security standards.",
        ],
      },
      industryInsights: {
        title: "Industry Insights",
        points: [
          "Fraud Detection and Customer Support: AI-powered platforms are crucial for fraud detection and personalized customer support. Comprehensive reporting and analytics can identify unusual patterns and flag potential fraud, while semantic intelligence can improve customer interactions, leading to higher satisfaction and trust.",
          "Market Growth: AI is pivotal in transforming financial services by enhancing customer interactions and ensuring data security. According to PwC, AI can enhance fraud detection, personalize customer experiences, and streamline regulatory compliance. Semantic intelligence analyzes vast amounts of interaction data to detect anomalies and prevent fraud, while AI-driven analytics provide deep insights into customer behavior, enabling personalized financial advice and services【PwC】.",
        ],
      },
      buttonLabel: "Get Demo",
      image: financialServices,
    },
    {
      id: 4,
      heading: "Insurance",
      subheading: "Deliver Exceptional Customer Service in Insurance",
      description:
        "Insurance companies can significantly enhance their operations with our AI-Powered Unified Customer Interaction Platform. Semantic Intelligence on Interactions delivers detailed insights from customer conversations, enabling agents to provide accurate and personalized support. The Agent Empowerment Suite offers continuous training and support, transforming agents into highly skilled professionals. Comprehensive Reporting and Analytics provide critical metrics and insights to refine customer service strategies. Additionally, Easy Integration and Data Privacy ensure a seamless and secure implementation within existing systems.",
      useCase: {
        title: "Use Case: Claim Processing Enhancement",
        points: [
          "Semantic Intelligence on Interactions: Analyzes customer calls to streamline the claim submission process and reduce resolution times.",
          "Agent Empowerment Suite: Equips agents with specialized training to handle complex insurance queries and claims efficiently.",
          "Comprehensive Reporting and Analytics: Monitors claim processing times and customer satisfaction to identify areas for improvement.",
          "Easy Integration and Data Privacy: Integrates with existing claim management systems while maintaining stringent data security protocols.",
        ],
      },
      industryInsights: {
        title: "Industry Insights",
        points: [
          "Claims Processing and Customer Engagement: AI is revolutionizing claims processing and customer engagement in the insurance industry. Semantic intelligence and comprehensive analytics streamline claims, reduce processing times, and provide personalized customer support. Studies suggest that AI-driven solutions enhance customer satisfaction and operational efficiency, making insurers more competitive【Accenture】.",
          "Operational Efficiency: AI in insurance improves customer interactions and operational efficiency. According to Accenture, AI automates claims processing, provides personalized customer service, and improves risk assessment accuracy. Semantic intelligence helps understand customer queries and streamline the claims process, while comprehensive analytics offer insights into customer behavior and risk profiles, leading to better decision-making and customer satisfaction【Accenture】.",
        ],
      },
      buttonLabel: "Get Demo",
      image: insurance,
    },
    {
      id: 5,
      heading: "HealthTech Solutions",
      subheading:
        "Enhancing Patient Engagement and Care with Intelligent Solutions",
      description:
        "Transform your healthtech operations with our advanced platform. Our Semantic Intelligence on Interactions analyzes patient conversations to provide insights, improving support during consultations. The Agent Empowerment Suite offers continuous training and upskilling, ensuring agents handle patient queries with precision and care. Comprehensive Reporting and Analytics allows you to monitor patient satisfaction, track consultation effectiveness, and optimize workflows. Additionally, Easy Integration and Data Privacy ensure seamless integration with EHR systems while maintaining compliance with healthcare data privacy regulations.",
      useCase: {
        title: "Use Case: Virtual Health Consultations",
        points: [
          "Semantic Intelligence on Interactions: Provides support for accurate and empathetic responses.",
          "Agent Empowerment Suite: Ensures agents are updated with the latest medical information and care techniques.",
          "Comprehensive Reporting and Analytics: Improves patient outcomes by tracking engagement and consultation effectiveness.",
          "Easy Integration and Data Privacy: Integrates smoothly with existing systems while safeguarding patient data.",
        ],
      },
      industryInsights: {
        title: "AI in Healthtech",
        points: [
          "AI is revolutionizing healthtech by improving patient interactions and operational efficiency. According to Frost & Sullivan, AI-powered chatbots and virtual assistants enhance patient engagement and satisfaction, while analytics drive personalized treatment plans and better healthcare outcomes.",
        ],
      },
      buttonLabel: "Get Demo",
      image: healthtech,
    },
    {
      id: 6,
      heading: "EdTech Solutions",
      subheading: "Transform Educational Support with AI-Powered Insights",
      description:
        "Elevate your educational support with our AI-Powered Unified Customer Interaction Platform. Semantic Intelligence on Interactions provides in-depth insights from student and parent conversations to enhance agent support. The Agent Empowerment Suite delivers continuous, tailored training, ensuring agents effectively address a variety of queries. Comprehensive Reporting and Analytics offer crucial metrics to optimize operations, while Easy Integration and Data Privacy ensure secure and seamless deployment within your existing systems.",
      useCase: {
        title: "Use Case: Student Support Services",
        points: [
          "Semantic Intelligence on Interactions: Delivers personalized academic assistance based on student inquiries.",
          "Agent Empowerment Suite: Ongoing training equips agents to handle diverse student needs, from technical issues to academic advising.",
          "Comprehensive Reporting and Analytics: Enhances services by monitoring student satisfaction and support request resolution times.",
          "Easy Integration and Data Privacy: Integrates with LMS and ensures secure handling of student data.",
        ],
      },
      industryInsights: {
        title: "AI in Edtech",
        points: [
          "AI is revolutionizing education by delivering personalized learning experiences and improving operational efficiency. HolonIQ reports significant growth in AI applications, including personalized learning paths and intelligent tutoring systems, which enhance student satisfaction and learning outcomes.",
        ],
      },
      buttonLabel: "Get Demo",
      image: edTech,
    },
    {
      id: 7,
      heading: "Recruiting and Staffing Solutions",
      subheading:
        "Streamline Recruitment with Intelligent Customer Interactions",
      description:
        "Optimize your recruitment process with our AI-Powered Unified Customer Interaction Platform. Semantic Intelligence on Interactions delivers insights from candidate and client conversations, enhancing support. The Agent Empowerment Suite provides ongoing training to elevate agent performance. Comprehensive Reporting and Analytics offer key metrics to refine recruitment strategies, while Easy Integration and Data Privacy ensure smooth integration with your systems and secure handling of sensitive information.",
      useCase: {
        title: "Use Case: Candidate Screening and Matching",
        points: [
          "Semantic Intelligence on Interactions: Streamlines candidate screening by analyzing interactions to find the best job matches.",
          "Agent Empowerment Suite: Equips recruiters with tools and training to enhance candidate engagement and interview effectiveness.",
          "Comprehensive Reporting and Analytics: Tracks metrics like time-to-hire and candidate satisfaction to improve recruitment strategies.",
          "Easy Integration and Data Privacy: Integrates with ATS and ensures secure, compliant data management.",
        ],
      },
      industryInsights: {
        title: "AI in Recruiting",
        points: [
          "AI enhances recruitment by automating resume screening and matching candidates to job requirements, as reported by Deloitte. Semantic intelligence and AI-driven analytics improve efficiency and candidate experience, optimizing hiring processes and strategies.",
        ],
      },
      buttonLabel: "Get Demo",
      image: recruitingStaffing,
    },
    {
      id: 8,
      heading: "Travel and Hospitality Solutions",
      subheading:
        "Elevate Guest Experience with Intelligent Customer Interactions",
      description:
        "Enhance guest interactions with our AI-powered platform. Semantic Intelligence on Interactions delivers insights to support agents. The Agent Empowerment Suite provides continuous training, ensuring efficient handling of guest queries. Comprehensive Reporting and Analytics offer performance metrics and trend analysis to optimize service. Easy Integration and Data Privacy ensure secure, seamless implementation with existing systems.",
      useCase: {
        title: "Use Case: Personalized Travel Assistance",
        points: [
          "Semantic Intelligence on Interactions: Provides tailored travel recommendations based on guest interactions.",
          "Agent Empowerment Suite: Equips agents with the knowledge to manage booking changes and offer local insights.",
          "Comprehensive Reporting and Analytics: Tracks guest satisfaction and service efficiency to improve the travel experience.",
          "Easy Integration and Data Privacy: Integrates with booking and management systems while ensuring data security.",
        ],
      },
      industryInsights: {
        title: "AI in Travel and Hospitality",
        points: [
          "According to McKinsey, AI-driven chatbots and virtual assistants improve customer satisfaction by handling inquiries and personalized recommendations. AI-powered analytics enhance understanding of travel trends and customer preferences, leading to better resource management and personalized marketing.",
        ],
      },
      buttonLabel: "Get Demo",
      image: travelHospitality,
    },
  ];

  return (
    <div className="solutions-page p-4 md:p-8 lg:p-16">
      <h1 className="text-4xl font-bold mb-8 text-center font-montserrat">
        Solutions
      </h1>

      {sections.map((section) => (
        <SolutionSection
          key={section.id}
          toggleModal={toggleModal}
          {...section}
        />
      ))}

      {/* Add more SolutionSection components here */}

      <motion.h2
        className="text-3xl md:text-4xl font-bold mb-4 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Explore How Super Agents Deliver Industry-Specific Solutions
      </motion.h2>
      <p className="text-base md:text-lg text-gray-600 mb-6 text-center">
        Each industry section highlights how our product features—Semantic
        Intelligence on Interactions, Agent Empowerment Suite, Comprehensive
        Reporting and Analytics, and Easy Integration and Data Privacy—address
        specific challenges, enhancing customer engagement and operational
        efficiency. By integrating relevant market studies and trends, we
        showcase the platform’s versatility and effectiveness. Discover tailored
        insights and studies for each industry to understand how our solutions
        drive value and reliability.
      </p>
      <div className="text-center">
        <motion.button
          className="bg-accent-500 text-white px-6 py-3 rounded-full shadow-md hover:bg-accent-600 transition-colors duration-300"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
          onClick={toggleModal}
        >
          Get Demo
        </motion.button>
      </div>
    </div>
  );
};

const SolutionSection = ({
  id,
  heading,
  subheading,
  description,
  useCase,
  industryInsights,
  image,
  buttonLabel,
  toggleModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section className="hover:shadow-md rounded-md gap-8 px-4 md:px-8 py-8 lg:py-12 lg:px-12 border hover:border-accent-300 transition-colors duration-150 mb-16 border-">
      <motion.div>
        <motion.h2
          className="text-3xl font-montserrat md:text-4xl font-bold mb-4 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {heading}
        </motion.h2>
        <motion.h3
          className="text-xl md:text-2xl text-gray-700 mb-4 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7 }}
        >
          {subheading}
        </motion.h3>
      </motion.div>
      <motion.div className="grid grid-cols-1 md:grid-cols-2 gap-8 place-items-center m-4 mt-8 lg:m-8">
        <motion.div
          className={`grid place-items-center w-full ${id % 2 === 0 ? "" : ""}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <img
            className="w-full h-full object-cover rounded-2xl"
            src={image}
            alt={`solution-${heading}`}
          />
        </motion.div>
        <motion.div>
          <p className="text-base text-center md:text-start md:text-lg text-gray-600 mb-6">
            {description}
          </p>
        </motion.div>
      </motion.div>

      <div className="grid-rows-2">
        {isOpen && (
          <>
            <div className="use-case mb-6">
              <h4 className="text-lg md:text-xl font-semibold mb-2">
                {useCase.title}
              </h4>
              <ul className="list-disc list-inside">
                {useCase.points.map((point, index) => (
                  <li
                    key={index}
                    className="text-base md:text-lg text-gray-600 mb-2"
                  >
                    {point}
                  </li>
                ))}
              </ul>
            </div>
            <div className="industry-insights mb-6">
              <h4 className="text-lg md:text-xl font-semibold mb-2">
                {industryInsights.title}
              </h4>
              <ul className="list-disc list-inside">
                {industryInsights.points.map((insight, index) => (
                  <li
                    key={index}
                    className="text-base md:text-lg text-gray-600 mb-2"
                  >
                    {insight}
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
        <div className="text-center">
          <motion.button
            title="Get Demo"
            className="bg-accent-500 text-white px-6 py-3 rounded-full shadow-md hover:bg-accent-600 transition-colors duration-300"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            onClick={toggleModal}
          >
            {buttonLabel}
          </motion.button>
          <motion.button
            title="Know more"
            className="border border-accent-500 ml-4 px-6 py-3 rounded-full shadow-md transition-colors duration-300"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {isOpen ? "Show less" : "Know more"}
          </motion.button>
        </div>
      </div>
    </section>
  );
};

export default SolutionsPage;
