import React from "react";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import heroImage from "../assets/hero.jpg";

const HeroSection = ({ toggleModal }) => {
  const { scrollY } = useViewportScroll();

  // Creating parallax effects for title, subtitle, and image
  const yTitle = useTransform(scrollY, [0, 300], [0, -75]);
  const ySubtitle = useTransform(scrollY, [0, 300], [0, -20]);
  const xImage = useTransform(scrollY, [0, 900], [0, 600]);
  const opacityImage = useTransform(scrollY, [0, 900], [1, 0]);

  return (
    <section
      id="about"
      className="h-screen flex flex-col-reverse md:flex-row justify-center items-center bg-secondary-50 text-center px-6 md:px-12 lg:px-24 py-12 relative overflow-hidden"
    >
      <div className="md:w-2/3">
        <motion.h1
          className="text-4xl md:text-5xl font-montserrat lg:text-6xl font-bold mb-4 md:mb-6 lg:mb-8 leading-tight"
          style={{ y: yTitle }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          From Caller to Super Agent - Elevate Every Call
        </motion.h1>
        <motion.p
          className=" text-lg md:text-xl lg:text-2xl text-gray-600 mb-8 md:mb-10 lg:mb-12 mx-auto md:mx-0"
          style={{ y: ySubtitle }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Transform Every Conversation into a Competitive Advantage with AI
        </motion.p>
        <motion.button
          className="bg-accent-500 text-white px-8 py-3 rounded-full shadow-lg hover:bg-accent-600 transition-colors duration-300"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
          onClick={toggleModal}
        >
          Get a Demo
        </motion.button>
      </div>

      <motion.div
        className="md:w-1/3 flex justify-center md:justify-end mb-8 md:mb-0"
        style={{ x: xImage, opacity: opacityImage }}
        initial={{ opacity: 0, scale: 0.8, x: 700 }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      >
        <img
          src={heroImage}
          alt="Parallax Square"
          className="w-60 md:w-80 lg:w-96 aspect-square object-cover rounded-lg shadow-lg"
        />
      </motion.div>
    </section>
  );
};

export default HeroSection;
