import React from "react";
import { motion, useTransform, useViewportScroll } from "framer-motion";

const CustomerAnecdote = () => {
  const { scrollY } = useViewportScroll();
  const ySection = useTransform(scrollY, [0, 300], [30, 0]);

  return (
    <motion.section
      id="customer-anecdote"
      className="py-16 md:py-20 bg-gray-50 text-center mx-auto px-6 md:px-12 lg:px-24"
      style={{ y: ySection }}
    >
      <motion.h2
        className="text-3xl md:text-4xl font-bold mb-8 md:mb-12 text-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        Customer Anecdote by Revv
      </motion.h2>
      <motion.div
        className="max-w-screen-sm md:max-w-screen-md mx-auto px-4 md:px-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
      >
        <p className="text-base md:text-lg text-gray-600 mb-4 md:mb-6">
          "Before using the AI-Powered Unified Customer Interaction Platform,
          our team struggled with managing customer interactions effectively.
          One pivotal moment was when an agent turned a dissatisfied client into
          a loyal advocate using insights from the platform. The integration was
          seamless, and the data privacy features assured us of our
          information's security. This platform has significantly enhanced our
          customer service operations and agent performance.“
        </p>
        <p className="text-gray-800 font-semibold text-sm md:text-base">
          — Anupam Kumar, Founder & CEO at Revv
        </p>
      </motion.div>
    </motion.section>
  );
};

export default CustomerAnecdote;
