import React from "react";
import { ReactComponent as Linkedin } from "../assets/socialmedia_linkedin.svg";
import { ReactComponent as Medium } from "../assets/socialmedia_medium.svg";
import blogImage1 from "../assets/blog_1.jpg";
import blogImage2 from "../assets/blog_2.png";
import blogImage3 from "../assets/blog_3.png";
import blogImage4 from "../assets/blog_4.png";
import blogImage5 from "../assets/blog_5.png";
import blogImage6 from "../assets/blog_6.png";
import blogImage7 from "../assets/blog_7.png";
import blogImage8 from "../assets/blog_8.png";

const blogs = [
  {
    title: "How Many R’s in Strawberry? Unraveling the Viral AI Glitch",
    content:
      "Dive into the viral trend that's puzzling AI and sparking debates. Is it just a funny mishap, or a glimpse into the future of AI development? Explore the unexpected twists and turns in our latest blog!",
    image: blogImage8,
    date: "2021-09-01",
    linkedin:
      "https://www.linkedin.com/posts/veritechinc_ai-generativeai-openai-activity-7236744684146982913-rLWi",
    medium:
      "https://medium.com/@keziah_18073/how-many-rs-in-strawberry-debc2e91ec27",
  },
  {
    title: "Lights, Camera, AI! Revolutionizing Content Creation",
    content:
      "Discover how AI is transforming video creation without cameras or sets! From hyper-realistic visuals to ethical challenges, explore the future of video with AI.",
    linkedin:
      "https://www.linkedin.com/posts/veritechinc_ai-generativeai-videogeneration-activity-7235268359813005313-_nvj",
    medium: "https://medium.com/@keziah_18073/lights-camera-ai-c6c0bc313eba",
    date: "Sep 3, 2024",
    image: blogImage7,
  },
  {
    title: "Why AI Agents Are the Future of Automation",
    content:
      "Explore how autonomous AI agents are reshaping industries from self-driving cars to robotic automation. Learn about their potential, challenges, and the future of AI-driven workflows.",
    linkedin:
      "https://www.linkedin.com/posts/veritechinc_artificialintelligence-ai-automation-activity-7234539005265014785-cHiQ",
    medium:
      "https://medium.com/@keziah_18073/ushering-in-the-next-era-of-automation-with-ai-agents-9a27e4c0535c",
    date: "Aug 31, 2024",
    image: blogImage6,
  },
  {
    title: "Why Did the GAN Apply for a Job as an Artist?",
    content:
      "Discover how Generative Adversarial Networks (GANs) are revolutionizing art, gaming, and healthcare while navigating ethical dilemmas like deepfakes. Join us as we delve into this cutting-edge technology!",
    linkedin:
      "https://www.linkedin.com/posts/veritechinc_ai-storytelling-contentcreation-activity-7230219339407228928-sqF-",
    medium:
      "https://medium.com/@keziah_18073/redefining-the-art-of-storytelling-with-llms-a506b75f514f",
    date: "Aug 16, 2024",
    image: blogImage5,
  },
  {
    title: "AI in Sports: A New Era of Performance and Strategy",
    content:
      "See how AI is revolutionizing sports, from personalized training to injury prevention. Learn how this unseen force is pushing athletes to new heights and transforming the future of sports.",
    linkedin:
      "https://www.linkedin.com/feed/update/urn:li:activity:7229492067381477378",
    medium:
      "https://medium.com/@keziah_18073/unmasking-gans-the-tech-behind-deep-fakes-and-the-future-of-ai-3a09dafad65b",
    date: "Aug 14, 2024",
    image: blogImage4,
  },
  {
    title: "The Future of Storytelling: How LLMs Are Changing Content Creation",
    content:
      "Explore how Large Language Models (LLMs) are reshaping storytelling, from personalized narratives to lifelike conversations. Discover the exciting possibilities in our latest blog!",
    linkedin:
      "https://www.linkedin.com/posts/veritechinc_olympics-sportstech-aiinsports-activity-7228759226821558272-U3Hk",
    medium:
      "https://medium.com/@keziah_18073/the-ai-playbook-meets-the-olympic-arena-fa241a78fd6d",
    date: "Aug 12, 2024",
    image: blogImage3,
  },
  {
    title: "Boost Your Productivity: The AI Way",
    content:
      "Find out how AI can streamline your workday, automate tedious tasks, and enhance your productivity. Discover the smart way to work with AI in our latest blog!",
    linkedin:
      " https://www.linkedin.com/posts/veritechinc_aiproductivity-worksmarter-automation-activity-7227631276097191936-oN8Q",
    medium:
      "https://medium.com/@keziah_18073/your-new-secret-weapon-to-increase-productivity-7e19cea66f13",
    date: "Aug 9, 2024",
    image: blogImage2,
  },
  {
    title:
      "Veritech.ai: Transforming Customer Interactions with Semantic Intelligence",
    content:
      "Uncover how Veritech.ai uses advanced AI to elevate customer service with real-time insights. Learn the difference between AI assistants, copilots, and agents to maximize AI's potential in your business.",
    linkedin:
      "https://www.linkedin.com/feed/update/urn:li:activity:7226210181594943488",
    medium: "https://medium.com/@keziah_18073/ai-assemble-3b240f64c70f",
    date: "Aug 5, 2024",
    image: blogImage1,
  },
];

const Blogs = () => {
  return (
    <section className="container mx-auto p-6">
      <h1 className="text-4xl font-bold mb-8 text-center font-montserrat">
        Our Blogs
      </h1>
      <div className="grid gap-8 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
        {blogs.map((blog, index) => (
          <BlogCard
            key={index}
            title={blog.title}
            description={blog.content}
            image={blog.image}
            linkedin={blog.linkedin}
            medium={blog.medium}
            date={blog.date}
          />
        ))}
      </div>
    </section>
  );
};

const BlogCard = ({ title, description, image, medium, linkedin, date }) => {
  return (
    <div
      title={title}
      className="relative border-2 hover:border-purple-400 bg-white  rounded-lg overflow-hidden transform transition duration-300 hover:shadow-lg flex flex-col justify-between"
    >
      <img className="w-full h-48 object-cover" src={image} alt={title} />
      <div className="p-6 flex flex-col flex-grow">
        <h2 className="text-2xl font-montserrat font-semibold mb-2">{title}</h2>
        <p className="text-gray-600 mb-4 flex-grow">{description}</p>
        <div className="flex justify-between items-center mt-auto">
          <span className="text-gray-600">Published on: {date}</span>
          <div className="flex gap-2">
            {linkedin && (
              <a
                href={linkedin}
                target="_blank"
                rel="noreferrer"
                className="flex items-center"
                title="LinkedIn"
              >
                <Linkedin className="w-7 h-7 shadow-md" />
              </a>
            )}
            {medium && (
              <a
                href={medium}
                target="_blank"
                rel="noreferrer"
                className="flex items-center"
                title="Medium"
              >
                <Medium className="w-7 h-7 shadow-md" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
