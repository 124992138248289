import React, { useState } from "react";
import { motion } from "framer-motion";
import veritechLogo from "../assets/veritech.png";
import { useNavigate } from "react-router-dom";

const Navbar = ({ toggleModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <motion.nav
      className="relative top-0 left-0 w-full bg-white shadow-sm"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <div className="container mx-auto flex items-center justify-between py-4 px-6">
        <motion.div
          className="text-2xl font-bold"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <img
            onClick={() => navigate("/")}
            src={veritechLogo}
            className="w-40"
            alt="Logo"
          />
        </motion.div>

        <div className="hidden md:flex space-x-8">
          <motion.button
            className="text-lg text-gray-700 hover:text-gray-900 outline-none"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            onClick={() => navigate("/")}
          >
            Home
          </motion.button>
          <motion.button
            className="text-lg text-gray-700 hover:text-gray-900 outline-none"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            onClick={() => navigate("/solutions")}
          >
            Solutions
          </motion.button>
          <motion.button
            className="text-lg text-gray-700 hover:text-gray-900 outline-none"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            onClick={() => navigate("/blogs")}
          >
            Blogs
          </motion.button>
          <motion.button
            className="text-lg text-gray-700 hover:text-gray-900 outline-none"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            onClick={toggleModal}
          >
            Contact Us
          </motion.button>
        </div>

        <div className="md:hidden">
          <button
            className="text-gray-700 focus:outline-none"
            onClick={handleMenuToggle}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="px-6 pb-4 space-y-4"
          >
            <motion.button
              className="block text-lg text-gray-700 hover:text-gray-900 outline-none"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              onClick={() => {
                navigate("/");
                setIsOpen(false);
              }}
            >
              Home
            </motion.button>
            <motion.button
              className="block text-lg text-gray-700 hover:text-gray-900 outline-none"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              onClick={() => {
                navigate("/solutions");
                setIsOpen(false);
              }}
            >
              Solutions
            </motion.button>
            <motion.button
              className="block text-lg text-gray-700 hover:text-gray-900 outline-none"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              onClick={() => {
                navigate("/blogs");
                setIsOpen(false);
              }}
            >
              Blogs
            </motion.button>
            <button
              className="block text-lg text-gray-700 hover:text-gray-900"
              onClick={() => {
                toggleModal();
                setIsOpen(false);
              }}
            >
              Contact Us
            </button>
          </motion.div>
        </div>
      )}
    </motion.nav>
  );
};

export default Navbar;
