import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const trackPageView = (url) => {
      if (window.gtag) {
        window.gtag("config", "G-B4Z8F4NFPG", {
          page_path: url,
        });
      }
    };

    trackPageView(location.pathname);
  }, [location]);
};

export default usePageTracking;
