import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import empowerment from "../assets/empowerment.jpg";
import privacy from "../assets/privacy.jpg";
import interaction from "../assets/interaction.jpg";
import reporting from "../assets/reporting.jpg";

// Define your features
const features = [
  {
    title: "Semantic Intelligence on Interactions",
    description:
      "Unlock the power of detailed insights from conversations between agents and customers. Our AI continuously learns from every interaction, enhancing its capabilities to provide better support and understanding.",
    icon: "🔍",
    id: "interaction",
    image: interaction,
  },
  {
    title: "Agent Empowerment Suite",
    description:
      "Transform your agents into super agents with our advanced tools. With semantic intelligence and continuous learning, agents receive tailored training and upskilling, enabling them to handle queries more effectively.",
    icon: "🚀",
    id: "empowerment",
    image: empowerment,
  },
  {
    title: "Comprehensive Reporting and Analytics",
    description:
      "Equip your team with powerful reports, analytics, and dashboards. Our platform offers performance metrics and actionable insights to help agents and managers make informed decisions.",
    icon: "📊",
    id: "reporting",
    image: reporting,
  },
  {
    title: "Easy Integration and Data Privacy",
    description:
      "Seamlessly integrate our platform with your existing systems while ensuring top-notch data privacy and security. Enjoy peace of mind knowing your data is safe and secure.",
    icon: "🔒",
    id: "privacy",
    image: privacy,
  },
];

const HowItWorks = () => {
  const { scrollY } = useViewportScroll();
  const ySection = useTransform(scrollY, [0, 300], [50, 0]);

  return (
    <motion.section
      id="how-it-works"
      className="py-16 md:py-20 bg-accent-50 text-center relative"
      style={{ y: ySection }}
    >
      <motion.h2
        className="text-3xl md:text-4xl lg:text-5xl font-bold mb-8 md:mb-12 text-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        How Will It Work?
      </motion.h2>
      <div className="container mx-auto px-4 lg:px-8">
        <div className="flex flex-col space-y-12 md:space-y-16">
          {features.map((feature, index) => (
            <div
              key={feature.id}
              className="grid grid-cols-1 place-items-center lg:grid-cols-2 min-h-[400px]"
            >
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8 }}
                className={`w-40 h-32 md:w-96 md:h-60 rounded-lg object-cover ${
                  index % 2 === 0 ? "lg:order-2" : "lg:order-1"
                }`}
                src={feature.image}
                alt={feature.title}
              />
              <FeatureItem feature={feature} index={index} />
            </div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

const FeatureItem = ({ feature, index }) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [hasBeenRevealed, setHasBeenRevealed] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHasBeenRevealed(true);
          observer.unobserve(ref.current);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <motion.div
      ref={ref}
      className={`relative p-6 md:p-8 ${
        index % 2 === 0 ? "lg:order-1" : "lg:order-2"
      }`}
      initial={{ opacity: 0, y: 40 }}
      animate={{
        opacity: hasBeenRevealed ? 1 : 0,
        y: hasBeenRevealed ? 0 : 40,
      }}
      transition={{ duration: 0.6, delay: index * 0.3 }}
    >
      <div
        className={`absolute top-4 right-10 -mt-12 md:-mt-6 -mr-4 md:-mr-6 text-5xl md:text-6xl`}
      ></div>
      <h3 className="text-xl md:text-2xl lg:text-3xl font-semibold text-gray-800 mb-4">
        {feature.title} <span className="text-accent-500">{feature.icon}</span>
      </h3>
      <p className="text-gray-600 text-base md:text-lg lg:text-xl mb-6">
        {feature.description}
      </p>
      <motion.button
        className="border border-accent-500 px-4 md:px-6 py-2 md:py-3 rounded-full shadow-md transition"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.1 }}
        onClick={() => navigate("/solutions")}
      >
        Know More
      </motion.button>
    </motion.div>
  );
};

export default HowItWorks;
