import React from "react";
import { motion } from "framer-motion";

const TransformInteractions = ({ toggleModal }) => {
  return (
    <section
      id="transform-interactions"
      className="py-16 bg-secondary-50 text-center px-6 sm:px-8 md:px-12 lg:px-16"
    >
      <motion.h2
        className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-8 sm:mb-10 lg:mb-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        Transform Customer Interactions with Intelligent Solutions
      </motion.h2>
      <motion.button
        className="bg-accent-500 text-white px-5 sm:px-6 py-3 sm:py-4 lg:px-8 lg:py-4 rounded-full shadow-lg"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
        onClick={toggleModal}
      >
        Get a Demo
      </motion.button>
    </section>
  );
};

export default TransformInteractions;
