import React, { useEffect } from "react";
import { motion } from "framer-motion";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="relative bg-secondary-50 text-gray-700 py-12 px-6 md:px-12 lg:px-24">
      <div className="relative max-w-2xl mx-auto">
        <motion.h1
          className="text-5xl font-montserrat font-extrabold mb-16 text-accent-500 leading-tight"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          Terms and Conditions
        </motion.h1>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Welcome to Veritech’s Super Agents Platform
        </motion.p>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Thank you for choosing Super Agents, an AI-powered unified customer
          interaction platform developed by Veritech. By accessing or using our
          website, you agree to comply with the following terms and conditions.
          If you do not agree to these terms, please refrain from using our
          website.
        </motion.p>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          1. Use of the Website
        </motion.h2>

        <motion.ul
          className="list-disc list-inside space-y-4 mb-8 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <li>
            <strong>Eligibility:</strong> By using this website, you confirm
            that you are at least 18 years old and legally capable of entering
            into binding contracts.
          </li>
          <li>
            <strong>User Responsibilities:</strong> You are responsible for your
            actions on our website, including any content you may post. We trust
            that you will use our platform in a lawful and respectful manner.
          </li>
          <li>
            <strong>Prohibited Activities:</strong> You agree not to engage in
            any activities that may harm our website, disrupt its operations, or
            violate any applicable laws.
          </li>
        </motion.ul>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          2. Intellectual Property
        </motion.h2>

        <motion.ul
          className="list-disc list-inside space-y-4 mb-8 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <li>
            <strong>Ownership:</strong> All content, features, and functionality
            on this website, including text, graphics, logos, and software, are
            the intellectual property of Veritech and are protected by
            applicable copyright, trademark, and other intellectual property
            laws.
          </li>
          <li>
            <strong>Limited License:</strong> You are granted a limited,
            non-exclusive, non-transferable license to access and use the
            website for personal or internal business purposes. This license
            does not permit any resale, commercial use, or modification of the
            content.
          </li>
        </motion.ul>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          3. Data Privacy and Security
        </motion.h2>

        <motion.ul
          className="list-disc list-inside space-y-4 mb-8 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <li>
            <strong>Your Privacy:</strong> We are committed to protecting your
            privacy and safeguarding your personal information. Please refer to
            our Privacy Policy for detailed information on how we collect, use,
            and protect your data.
          </li>
          <li>
            <strong>Data Security:</strong> While we take all reasonable
            precautions to secure your data, we cannot guarantee absolute
            security. By using our website, you acknowledge and accept the
            inherent risks associated with online data transmission.
          </li>
        </motion.ul>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          4. Third-Party Links
        </motion.h2>

        <motion.ul
          className="list-disc list-inside space-y-4 mb-8 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <li>
            <strong>External Websites:</strong> Our website may contain links to
            third-party websites that are not operated or controlled by
            Veritech. We are not responsible for the content, privacy policies,
            or practices of these external sites.
          </li>
          <li>
            <strong>Use of Third-Party Links:</strong> Any links to third-party
            websites are provided for your convenience and do not imply
            endorsement or approval by Veritech. We encourage you to review the
            terms and privacy policies of any third-party sites you visit.
          </li>
        </motion.ul>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          5. Disclaimers and Limitation of Liability
        </motion.h2>

        <motion.ul
          className="list-disc list-inside space-y-4 mb-8 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <li>
            <strong>Website “As Is”:</strong> The content and services provided
            on our website are offered on an "as is" and "as available" basis
            without any warranties of any kind, either express or implied.
          </li>
          <li>
            <strong>No Guarantees:</strong> We do not guarantee the accuracy,
            reliability, or completeness of any information on our website. You
            agree that your use of our site is at your own risk.
          </li>
          <li>
            <strong>Limitation of Liability:</strong> To the fullest extent
            permitted by law, Veritech and its affiliates, officers, employees,
            and agents shall not be liable for any direct, indirect, incidental,
            consequential, or punitive damages arising out of your use of the
            website or any content provided therein.
          </li>
        </motion.ul>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          6. Termination of Access
        </motion.h2>

        <motion.ul
          className="list-disc list-inside space-y-4 mb-8 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <li>
            <strong>Termination Rights:</strong> We reserve the right to
            terminate or suspend your access to our website at any time, with or
            without cause, and without prior notice. This may include removing
            any content you have posted or restricting your ability to use
            certain features of the website.
          </li>
          <li>
            <strong>Effect of Termination:</strong> Upon termination, your right
            to use the website will immediately cease. Any provisions of these
            terms that by their nature should survive termination shall continue
            to apply.
          </li>
        </motion.ul>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          7. Governing Law
        </motion.h2>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          These terms and conditions shall be governed by and construed in
          accordance with the laws of [Your Country/State]. Any disputes arising
          out of or relating to these terms shall be subject to the exclusive
          jurisdiction of the courts in [Your City/State].
        </motion.p>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          8. Changes to Terms and Conditions
        </motion.h2>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Veritech reserves the right to modify or update these terms and
          conditions at any time, and any changes will be effective immediately
          upon posting to the website. It is your responsibility to review these
          terms regularly to stay informed of any updates.
        </motion.p>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          9. Contact Us
        </motion.h2>

        <motion.p
          className="text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          If you have any questions, concerns, or feedback regarding these terms
          and conditions, please contact us at{" "}
          <a
            href="mailto:connect@veritech.ai"
            className="text-accent-500 underline"
          >
            connect@veritech.ai
          </a>
          .
        </motion.p>
      </div>
    </main>
  );
};

export default Terms;
