import React, { useEffect } from "react";
import { motion } from "framer-motion";

const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="relative bg-secondary-50 text-gray-700 py-12 px-6 md:px-12 lg:px-24">
      <div className="relative max-w-2xl mx-auto">
        <motion.h1
          className="text-5xl font-extrabold mb-16 text-accent-500 leading-tight"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          Privacy Policy
        </motion.h1>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Welcome to Veritech’s Super Agents Platform
        </motion.p>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Your privacy is of utmost importance to us. This Privacy Policy
          outlines how we collect, use, and protect your information when you
          visit our website or use our AI-powered unified customer interaction
          platform, Super Agents.
        </motion.p>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Information We Collect
        </motion.h2>

        <motion.ul
          className="list-disc list-inside space-y-4 mb-8 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <li>
            <strong>Personal Information:</strong> When you contact us, sign up
            for a demo, or interact with our platform, we may collect personal
            details such as your name, email address, and phone number.
          </li>
          <li>
            <strong>Usage Data:</strong> We may collect data on how you use our
            website and platform, including pages visited, features used, and
            interactions with our content. This helps us improve our services
            and deliver a better user experience.
          </li>
          <li>
            <strong>Cookies and Tracking Technologies:</strong> We may use
            cookies and similar tracking technologies to enhance your browsing
            experience and gather information about your preferences.
          </li>
        </motion.ul>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          How We Use Your Information
        </motion.h2>

        <motion.ul
          className="list-disc list-inside space-y-4 mb-8 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <li>
            <strong>Provide and Improve Services:</strong> We use your
            information to deliver our services, personalize your experience,
            and enhance the functionality of our platform.
          </li>
          <li>
            <strong>Communication:</strong> We may use your contact details to
            communicate with you about product updates, promotions, or to
            provide customer support.
          </li>
          <li>
            <strong>Analytics and Reporting:</strong> Data collected from your
            interactions with our platform may be used for analytics and
            reporting purposes, helping us understand usage patterns and improve
            our offerings.
          </li>
        </motion.ul>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Data Security
        </motion.h2>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          We prioritize the security of your data. Our platform is designed with
          robust security measures to protect your information from unauthorized
          access, disclosure, alteration, or destruction. We adhere to industry
          best practices and comply with applicable data protection laws to
          ensure your data remains safe.
        </motion.p>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Data Sharing and Disclosure
        </motion.h2>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          We do not sell, trade, or rent your personal information to third
          parties. We may share your data with trusted partners who assist us in
          operating our platform, conducting our business, or servicing you, so
          long as they agree to keep your information confidential.
        </motion.p>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Your Rights
        </motion.h2>

        <motion.ul
          className="list-disc list-inside space-y-4 mb-8 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <li>
            <strong>Access Your Information:</strong> Request a copy of the
            personal data we hold about you.
          </li>
          <li>
            <strong>Correct Your Information:</strong> Request corrections to
            any inaccurate or incomplete data.
          </li>
          <li>
            <strong>Request Deletion:</strong> Ask us to delete your personal
            information, subject to certain legal obligations.
          </li>
        </motion.ul>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Third-Party Links
        </motion.h2>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Our website may contain links to third-party sites. Please note that
          we are not responsible for the privacy practices or the content of
          these external sites. We encourage you to review their privacy
          policies before providing any personal information.
        </motion.p>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Changes to This Policy
        </motion.h2>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          We may update our Privacy Policy from time to time. Any changes will
          be posted on this page, and we will notify you of significant changes
          through email or a notice on our website.
        </motion.p>

        <motion.h2
          className="text-3xl font-semibold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Contact Us
        </motion.h2>

        <motion.p
          className="mb-6 text-lg leading-relaxed"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          If you have any questions or concerns about our Privacy Policy or how
          we handle your data, please reach out to us at{" "}
          <a
            href="mailto:connect@veritech.ai"
            className="text-accent-500 underline"
          >
            connect@veritech.ai
          </a>
          . We’re here to help and ensure your experience with Super Agents is
          both secure and satisfactory.
        </motion.p>
      </div>
    </main>
  );
};

export default Policy;
