import React, { useEffect, useState } from "react";
import { ReactComponent as Cross } from "../assets/cross.svg";

const ContactModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // setting up the form data state
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // function to send the email (curently using google app script)
  const handleSend = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.name || !formData.email || !formData.message) {
      setError("Please fill all the required fields.");
      return;
    }

    try {
      // this appscript url is created under veritech.ai domain
      const WEB_SCRIPT_URL =
        "https://script.google.com/macros/s/AKfycbxVW-4PBiQZT_C_KCHwSLjfRvtNMKmew0Br-GmwXE1UV9WkpiMUItMXS0aEYrT0acnxCQ/exec";
      const response = await fetch(WEB_SCRIPT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      });
      const result = await response.json();

      if (result.result === "success") {
        setTimeout(() => {
          onClose(true);
          setFormData({
            name: "",
            email: "",
            message: "",
            phone: "",
          });
        }, 500);
      } else {
        console.log("Something went wrong while sending email", result);
        // Handle error
        setError("Something went wrong while sending email");
      }
    } catch (error) {
      console.log("Something went wrong while sending email", error);
      setError("Something went wrong while sending email");
    } finally {
      setLoading(false);
    }
  };

  // clear the error message after 1 second if there is
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 1000);
    }
  }, [formData]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="relative bg-white rounded-lg shadow-lg p-8 w-full max-w-md mx-4">
        <button
          className="absolute top-2 right-2 border border-accent-500 text-accent-500 hover:text-accent-600 rounded-full"
          onClick={() => onClose(false)} // Close the modal when the cross button is clicked
        >
          <Cross />
        </button>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
          Contact Us
        </h2>
        <form onSubmit={handleSend}>
          <div className="mb-4">
            <label className="block text-sm text-gray-600 mb-1">Name</label>
            <input
              disabled={loading}
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full p-2 border outline-none border-accent-100 focus:border-accent-500 transition-colors duration-150 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm text-gray-600 mb-1">Email</label>
            <input
              disabled={loading}
              type="email"
              name="email"
              value={formData.email}
              required
              onChange={handleChange}
              className="w-full p-2 border outline-none border-accent-100 focus:border-accent-500 transition-colors duration-150 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm text-gray-600 mb-1">Phone</label>
            <input
              disabled={loading}
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border outline-none border-accent-100 focus:border-accent-500 transition-colors duration-150 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm text-gray-600 mb-1">Message</label>
            <textarea
              disabled={loading}
              name="message"
              value={formData.message}
              required
              onChange={handleChange}
              className="w-full p-2 border outline-none border-accent-100 focus:border-accent-500 transition-colors duration-150 rounded"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className="bg-accent-500 text-white px-6 py-2 rounded-full shadow-lg"
            >
              Send
            </button>
          </div>
          {loading && (
            <p className="text-gray-500 text-sm mt-2 text-center">Sending...</p>
          )}
          {error && (
            <p className="text-red-500 text-sm mt-2 text-center">{error}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactModal;
