import React from "react";
import { motion } from "framer-motion";

const NotFoundPage = () => {
  return (
    <div className="not-found-page flex flex-col justify-center items-center h-[687px] p-8 md:p-16 lg:p-24 text-center">
      <motion.div
        className="text-5xl md:text-6xl font-bold mb-6 text-accent-500"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        404
      </motion.div>
      <motion.h1
        className="text-3xl md:text-4xl font-semibold mb-4 text-gray-800"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        Oops! Page Not Found
      </motion.h1>
      <motion.p
        className="text-lg md:text-xl text-gray-600 mb-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        The page you are looking for does not exist. It might have been removed,
        or the URL might be incorrect.
      </motion.p>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <a
          href="/"
          className="bg-accent-500 text-white px-6 py-3 rounded-full shadow-md hover:bg-accent-600 transition-colors duration-300"
        >
          Go Back to Home
        </a>
      </motion.div>
    </div>
  );
};

export default NotFoundPage;
