import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <motion.footer className="bg-accentDark-700 text-gray-400 p-6 md:p-10">
      <div className="max-w-6xl mx-auto mb-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8 text-sm">
        <div className="w-full">
          <h2 className="text-xl font-bold text-accent-50 mb-2">
            Veritech Inc.
          </h2>
          <p>Interaction Intelligence Elevated by AI</p>
        </div>
        <div className="w-full">
          <h3 className="font-semibold text-accent-50 mb-2">Contact</h3>
          <p>896 S State St, Unit #1151, Dover, DE 19901, USA</p>
          <a
            href="mailto:connect@veritech.ai"
            className="hover:text-accent-300"
          >
            connect@veritech.ai
          </a>
        </div>
        <div className="w-full">
          <h3 className="font-semibold text-accent-50 mb-2">Quick Links</h3>
          <ul className="space-y-1">
            <li>
              <Link
                to="/terms-conditions"
                className="hover:text-blue-400 transition-colors"
              >
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link
                to="privacy-policy"
                className="hover:text-blue-400 transition-colors"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className="w-full">
          <h3 className="font-semibold text-accent-50 mb-2">Follow Us</h3>
          <div className="space-y-1">
            {/* <a href="#" className="hover:text-accent-300 transition-colors">
              Twitter
            </a> */}
            <a
              href="https://www.linkedin.com/company/veritechinc/"
              className="hover:text-accent-300 transition-colors"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
            {/* <a href="#" className="hover:text-accent-300 transition-colors">
              Facebook
            </a> */}
          </div>
        </div>
        {/* <div className="w-full">
          <h3 className="font-semibold text-accent-50 mb-4">Subscribe Us</h3>
          <form className="flex flex-col sm:flex-row">
            <input
              type="email"
              placeholder="Email"
              className="px-4 py-2 border border-accent-500 outline-none focus:border-accent-300 bg-gray-800 text-accent-50 rounded-full sm:rounded-l-full sm:rounded-r-none"
            />
            <button
              type="submit"
              className="bg-accent-500 text-white px-5 py-2 mt-2 sm:mt-0 rounded-full sm:rounded-r-full sm:rounded-l-none hover:bg-accent-600 transition-colors"
            >
              Subscribe
            </button>
          </form>
        </div> */}
      </div>
      <div className="text-center text-xs text-gray-500">
        © 2024 Veritech Inc. All rights reserved.
      </div>
    </motion.footer>
  );
};

export default Footer;
